<template>
  <div>
    <v-layout style="margin: 0; margin-bottom: 10px" row wrap>
      <v-flex sm6>
        <h1>Dashboard</h1>
      </v-flex>
      <v-flex
        sm6
        :style="{ 'text-align': $vuetify.breakpoint.xs ? 'center' : 'right' }"
      >
        <v-btn
          title="- 1 week"
          text
          style="color: grey"
          @click="
            () => {
              current_date.setDate(current_date.getDate() - 7);
              current_date = new Date(current_date); // For reactivity changes
              l_booked_today = false;
              loadTodayRecords();
            }
          "
          ><i class="far fa-chevron-double-left"></i
        ></v-btn>
        <v-btn
          title="- 1 day"
          text
          @click="
            () => {
              current_date.setDate(current_date.getDate() - 1);
              current_date = new Date(current_date); // For reactivity changes
              l_booked_today = false;
              loadTodayRecords();
            }
          "
          ><i class="far fa-chevron-left"></i
        ></v-btn>
        <v-btn
          title="Today"
          text
          color="primary"
          @click="
            () => {
              current_date = new Date();
              current_date = new Date(current_date); // For reactivity changes
              l_booked_today = false;
              loadTodayRecords();
            }
          "
          ><i class="far fa-circle"></i
        ></v-btn>
        <v-btn
          title="+ 1 day"
          text
          @click="
            () => {
              current_date.setDate(current_date.getDate() + 1);
              current_date = new Date(current_date); // For reactivity changes
              l_booked_today = false;
              loadTodayRecords();
            }
          "
          ><i class="far fa-chevron-right"></i
        ></v-btn>
        <v-btn
          title="+ 1 week"
          text
          style="color: grey"
          @click="
            () => {
              current_date.setDate(current_date.getDate() + 7);
              current_date = new Date(current_date); // For reactivity changes
              l_booked_today = false;
              loadTodayRecords();
            }
          "
          ><i class="far fa-chevron-double-right"></i
        ></v-btn>
      </v-flex>
    </v-layout>
    <h3>
      {{
        dateFunctions.parseDate(current_date) ===
        dateFunctions.parseDate(new Date())
          ? "Today"
          : dateFunctions.getDayString(current_date)
      }}
      ({{ dateFunctions.formatDate(dateFunctions.parseDate(current_date)) }})
    </h3>
    <v-layout style="margin: 10px" row wrap>
      <v-flex xs6 sm3>
        <Tile
          :loading="l_booked_today"
          :value="Math.round(booked_today * 100) / 100 + ' h'"
          name="Booked today"
        ></Tile>
      </v-flex>
      <v-flex xs6 sm3>
        <Tile
          :loading="l_booked_today"
          :value="booked_projects_today.length + ' projects'"
          name="Booked projects today"
        ></Tile>
      </v-flex>
      <v-flex xs12 sm3>
        <Tile
          :loading="l_booked_today"
          :value="avg_booked_hours_per_project_today + ' h / project'"
          name="Average hours per project"
        ></Tile>
      </v-flex>
    </v-layout>
    <v-divider></v-divider>
    <v-layout>
      <v-flex sm6 xs12>
        <h3 style="margin-top: 15px">
          Weekly (KW {{ getWeekNumber(current_date)[1] }})
        </h3>
        <v-layout style="margin: 10px" row wrap>
          <v-flex xs12 sm6>
            <Tile
              :loading="l_booked_week"
              :value="
                Math.round(booked_this_week * 100) / 100 +
                (hours_per_week > 0 ? ' / ' + hours_per_week : '') +
                ' h'
              "
              name="Booked"
            ></Tile>
          </v-flex>
          <v-flex xs12 sm6>
            <Tile
              :loading="l_booked_week"
              :value="booked_projects_this_week.length + ' projects'"
              name="Booked projects"
            ></Tile>
          </v-flex>
          <v-flex xs12 sm6>
            <Tile
              v-if="hours_per_week > 0"
              :loading="l_booked_week"
              :value="percentage_booked_this_week + '%'"
              :color="percentage_booked_this_week > 105 ? 'red' : ''"
              name="Percentage booked"
            ></Tile>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex sm6 xs12>
        <h3 style="margin-top: 15px">
          Monthly ({{ dateFunctions.parseDate(current_date).substr(0, 7) }})
        </h3>
        <v-layout style="margin: 10px" row wrap>
          <v-flex xs12 sm6>
            <Tile
              :loading="l_booked_this_month"
              :value="Math.round(booked_this_month * 100) / 100 + ' h'"
              name="Booked"
            ></Tile>
          </v-flex>
          <v-flex xs12 sm6>
            <Tile
              :loading="l_booked_this_month"
              :value="booked_projects_this_month.length + ' projects'"
              name="Booked projects"
            ></Tile>
          </v-flex>
          <v-flex xs12 sm6>
            <Tile
              style="color:red;"
              icon="fa-info-circle"
              :loading="l_booked_this_month"
              :value="total_further_action_this_month + ' records'"
              name="Total 'Further action'"
            ></Tile>
          </v-flex>
          <v-flex xs12 sm6>
            <Tile
              style="color:orange;"
              icon="fa-info-circle"
              :loading="l_booked_this_month"
              :value="total_need_to_change_this_month + ' records'"
              name="Total 'Need to be changed'"
            ></Tile>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-divider></v-divider>
    <h3 style="margin-top: 15px;">Records ({{ dateFunctions.formatDate(current_date) }})</h3>
    <v-data-table
      :headers="[
        {
          text: 'Task No.',
          value: 'task_no',
          width: '10%',
        },
        {
          text: 'Description',
          value: 'description',
          width: '75%',
        },
        {
          text: 'Duration',
          value: 'duration',
          width: '15%',
        },
      ]"
      :items="tab_booked_today"
      :loading="l_booked_today"
      sort-by="duration"
      :multi-sort="true"
      :sort-desc="true"
      group-by="project_id"
      no-data-text="There are no records for today."
      @click:row="triggerSelectBookedTodayRecord"
    >
      <template v-slot:[`item.description`]="{ item }">
        <i class="fad fa-info-circle" v-if="item.marked_need_to_change === 'true'" title="Need to be changed" style="color:orange;"></i>
        <i class="fad fa-info-circle" v-if="item.marked_further_action === 'true'" title="Further action" style="color:red;"></i>
        <span>{{ item.description }}</span>
      </template>
      <template v-slot:[`item.duration`]="{ item }">
        {{ item.duration }} h
      </template>
      <template v-slot:[`group.header`]="{ items }">
        <td colspan="2">
          {{ project_mapping[items[0].project_id] }}
        </td>
        <td colspan="2">{{ getTotalDuration(items) }} h</td>
      </template></v-data-table
    >
  </div>
</template>

<script>
import dateFunctions from "../logic/date";
import { storage } from "../schmucklicloud";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      today_records: [],
      this_month_records: [],

      week_records: [],

      l_booked_today: true,
      l_booked_week: true,
      l_booked_this_month: true,

      current_date: new Date(),
      previous_date: new Date(),

      project_mapping: {},

      // Settings
      hours_per_week: 0,

      dateFunctions: dateFunctions,
    };
  },
  computed: {
    booked_today() {
      var hours = 0;

      this.today_records.forEach(function (record) {
        hours += parseFloat(record.data.duration);
      });

      return hours;
    },
    booked_projects_today() {
      var projects = [];

      this.today_records.forEach(function (record) {
        var project_id = record.data.project_id;
        if (!projects.includes(project_id)) {
          projects.push(project_id);
        }
      });

      return projects;
    },
    avg_booked_hours_per_project_today() {
      var avg =
        Math.round(
          (this.booked_today / this.booked_projects_today.length) * 100
        ) / 100;
      return Number.isNaN(avg) ? "0" : avg;
    },
    booked_this_week() {
      var hours = 0;

      this.week_records.forEach(function (record) {
        hours += parseFloat(record.data.duration);
      });

      return hours;
    },
    booked_projects_this_week() {
      var projects = [];

      this.week_records.forEach(function (record) {
        var project_id = record.data.project_id;
        if (!projects.includes(project_id)) {
          projects.push(project_id);
        }
      });

      return projects;
    },
    percentage_booked_this_week() {
      return Math.round((this.booked_this_week / this.hours_per_week) * 100);
    },
    booked_this_month() {
      var hours = 0;

      this.this_month_records.forEach(function (record) {
        hours += parseFloat(record.data.duration);
      });

      return hours;
    },
    booked_projects_this_month() {
      var projects = [];

      this.this_month_records.forEach(function (record) {
        var project_id = record.data.project_id;
        if (!projects.includes(project_id)) {
          projects.push(project_id);
        }
      });

      return projects;
    },
    total_further_action_this_month() {
      var total = 0;
      this.this_month_records.forEach(function (record) {
        if (record.data.marked_further_action === 'true') {
          total++;
        }
      });
      return total;
    },
    total_need_to_change_this_month() {
      var total = 0;
      this.this_month_records.forEach(function (record) {
        console.log(record);
        if (record.data.marked_need_to_change === 'true') {
          total++;
        }
      });
      return total;
    },
    tab_booked_today() {
      var table = [];

      this.today_records.forEach(function (item) {
        table.push({
          task_no: item.data.task_no,
          description: item.data.description,
          duration: item.data.duration,
          id: item.id,
          project_id: item.data.project_id,
          marked_need_to_change: item.data.marked_need_to_change,
          marked_further_action: item.data.marked_further_action
        });
      });
      return table;
    },
  },
  async mounted() {
    if (Cookies.get("current_date") !== undefined) {
      this.current_date = new Date(Cookies.get("current_date"));
    }

    // First load all projects to a mapping table
    await this.loadProjects();

    this.loadTodayRecords();
    this.loadWeekRecords();
    this.loadMonthsRecords();
    this.loadSettings();
  },
  methods: {
    async loadProjects() {
      var response = await storage.getAll("projects");
      if (response.isOK) {
        response.data.forEach((project) => {
          this.project_mapping[project.id] = project.data.name;
        });
      }
    },
    async loadTodayRecords() {
      var response = await storage.get("times", [
        {
          column: "date",
          operator: "==",
          value: dateFunctions.parseDate(this.current_date),
        },
      ]);

      this.l_booked_today = false;

      if (response.isOK) {
        this.today_records = response.data || [];
      } else {
        this.$notify.toast(response.message);
      }
    },
    async loadMonthsRecords() {
      this.l_booked_this_month = true;
      var response = await storage.get("times", [
        {
          column: "date",
          operator: "starts",
          value: dateFunctions.parseDate(this.current_date).substr(0, 7),
        },
      ]);

      this.l_booked_this_month = false;

      if (response.isOK) {
        this.this_month_records = response.data || [];
      } else {
        this.$notify.toast(response.message);
      }
    },
    async triggerSelectBookedTodayRecord(item) {
      var getClientResponse = await storage.getById(
        "projects",
        item.project_id
      );

      if (getClientResponse.isOK) {
        var client_id = getClientResponse.data.data.client_id;
        this.$router.push("/d/" + client_id + "/" + item.project_id);
      }
    },
    /*async getProjectName(project_id) {
      var response = await storage.getById("projects", project_id);

      if (response.isOK) {
        return response.data.data.name;
      }
    },*/
    getTotalDuration(items) {
      var total = 0;

      items.forEach(function (item) {
        total += parseFloat(item.duration);
      });
      return total;
    },
    async loadWeekRecords() {
      var first_day = this.getMonday(this.current_date);
      this.week_records = [];

      for (var i = 0; i < 7; i++) {
        var response = await storage.get("times", [
          {
            column: "date",
            operator: "==",
            value: dateFunctions.parseDate(first_day),
          },
        ]);

        if (response.isOK && response.data) {
          response.data.forEach(
            function (item) {
              this.week_records.push(item);
            }.bind(this)
          );
        }
        if (i === 6) {
          this.l_booked_week = false;
        }

        first_day.setDate(first_day.getDate() + 1);
      }
    },
    async loadSettings() {
      var response = await storage.getAll("settings");
      if (response.isOK) {
        if (response.data) {
          this.hours_per_week = response.data[0].data.hours_per_week;
        }
      }
    },
    getMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1);
      return new Date(d.setDate(diff));
    },
    getWeekNumber(d) {
      d = new Date(d);
      // Copy date so don't modify original
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      // Set to nearest Thursday: current date + 4 - current day number
      // Make Sunday's day number 7
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
      // Get first day of year
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      // Calculate full weeks to nearest Thursday
      var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
      // Return array of year and week number
      return [d.getUTCFullYear(), weekNo];
    },
  },
  watch: {
    current_date(current) {
      // Store the current date for fifteen minutes
      var inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);
      Cookies.set("current_date", current, { expires: inFifteenMinutes });

      if (
        this.getMonday(current).getDate() !==
        this.getMonday(this.previous_date).getDate()
      ) {
        this.l_booked_week = true;
        this.loadWeekRecords();
        this.loadMonthsRecords();
      }
      this.previous_date = new Date(current);
    },
  },
};
</script>

<style>
</style>