var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{staticStyle:{"margin":"0","margin-bottom":"10px"},attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"sm6":""}},[_c('h1',[_vm._v("Dashboard")])]),_c('v-flex',{style:({ 'text-align': _vm.$vuetify.breakpoint.xs ? 'center' : 'right' }),attrs:{"sm6":""}},[_c('v-btn',{staticStyle:{"color":"grey"},attrs:{"title":"- 1 week","text":""},on:{"click":function () {
            _vm.current_date.setDate(_vm.current_date.getDate() - 7);
            _vm.current_date = new Date(_vm.current_date); // For reactivity changes
            _vm.l_booked_today = false;
            _vm.loadTodayRecords();
          }}},[_c('i',{staticClass:"far fa-chevron-double-left"})]),_c('v-btn',{attrs:{"title":"- 1 day","text":""},on:{"click":function () {
            _vm.current_date.setDate(_vm.current_date.getDate() - 1);
            _vm.current_date = new Date(_vm.current_date); // For reactivity changes
            _vm.l_booked_today = false;
            _vm.loadTodayRecords();
          }}},[_c('i',{staticClass:"far fa-chevron-left"})]),_c('v-btn',{attrs:{"title":"Today","text":"","color":"primary"},on:{"click":function () {
            _vm.current_date = new Date();
            _vm.current_date = new Date(_vm.current_date); // For reactivity changes
            _vm.l_booked_today = false;
            _vm.loadTodayRecords();
          }}},[_c('i',{staticClass:"far fa-circle"})]),_c('v-btn',{attrs:{"title":"+ 1 day","text":""},on:{"click":function () {
            _vm.current_date.setDate(_vm.current_date.getDate() + 1);
            _vm.current_date = new Date(_vm.current_date); // For reactivity changes
            _vm.l_booked_today = false;
            _vm.loadTodayRecords();
          }}},[_c('i',{staticClass:"far fa-chevron-right"})]),_c('v-btn',{staticStyle:{"color":"grey"},attrs:{"title":"+ 1 week","text":""},on:{"click":function () {
            _vm.current_date.setDate(_vm.current_date.getDate() + 7);
            _vm.current_date = new Date(_vm.current_date); // For reactivity changes
            _vm.l_booked_today = false;
            _vm.loadTodayRecords();
          }}},[_c('i',{staticClass:"far fa-chevron-double-right"})])],1)],1),_c('h3',[_vm._v(" "+_vm._s(_vm.dateFunctions.parseDate(_vm.current_date) === _vm.dateFunctions.parseDate(new Date()) ? "Today" : _vm.dateFunctions.getDayString(_vm.current_date))+" ("+_vm._s(_vm.dateFunctions.formatDate(_vm.dateFunctions.parseDate(_vm.current_date)))+") ")]),_c('v-layout',{staticStyle:{"margin":"10px"},attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs6":"","sm3":""}},[_c('Tile',{attrs:{"loading":_vm.l_booked_today,"value":Math.round(_vm.booked_today * 100) / 100 + ' h',"name":"Booked today"}})],1),_c('v-flex',{attrs:{"xs6":"","sm3":""}},[_c('Tile',{attrs:{"loading":_vm.l_booked_today,"value":_vm.booked_projects_today.length + ' projects',"name":"Booked projects today"}})],1),_c('v-flex',{attrs:{"xs12":"","sm3":""}},[_c('Tile',{attrs:{"loading":_vm.l_booked_today,"value":_vm.avg_booked_hours_per_project_today + ' h / project',"name":"Average hours per project"}})],1)],1),_c('v-divider'),_c('v-layout',[_c('v-flex',{attrs:{"sm6":"","xs12":""}},[_c('h3',{staticStyle:{"margin-top":"15px"}},[_vm._v(" Weekly (KW "+_vm._s(_vm.getWeekNumber(_vm.current_date)[1])+") ")]),_c('v-layout',{staticStyle:{"margin":"10px"},attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('Tile',{attrs:{"loading":_vm.l_booked_week,"value":Math.round(_vm.booked_this_week * 100) / 100 +
              (_vm.hours_per_week > 0 ? ' / ' + _vm.hours_per_week : '') +
              ' h',"name":"Booked"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('Tile',{attrs:{"loading":_vm.l_booked_week,"value":_vm.booked_projects_this_week.length + ' projects',"name":"Booked projects"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":""}},[(_vm.hours_per_week > 0)?_c('Tile',{attrs:{"loading":_vm.l_booked_week,"value":_vm.percentage_booked_this_week + '%',"color":_vm.percentage_booked_this_week > 105 ? 'red' : '',"name":"Percentage booked"}}):_vm._e()],1)],1)],1),_c('v-flex',{attrs:{"sm6":"","xs12":""}},[_c('h3',{staticStyle:{"margin-top":"15px"}},[_vm._v(" Monthly ("+_vm._s(_vm.dateFunctions.parseDate(_vm.current_date).substr(0, 7))+") ")]),_c('v-layout',{staticStyle:{"margin":"10px"},attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('Tile',{attrs:{"loading":_vm.l_booked_this_month,"value":Math.round(_vm.booked_this_month * 100) / 100 + ' h',"name":"Booked"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('Tile',{attrs:{"loading":_vm.l_booked_this_month,"value":_vm.booked_projects_this_month.length + ' projects',"name":"Booked projects"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('Tile',{staticStyle:{"color":"red"},attrs:{"icon":"fa-info-circle","loading":_vm.l_booked_this_month,"value":_vm.total_further_action_this_month + ' records',"name":"Total 'Further action'"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('Tile',{staticStyle:{"color":"orange"},attrs:{"icon":"fa-info-circle","loading":_vm.l_booked_this_month,"value":_vm.total_need_to_change_this_month + ' records',"name":"Total 'Need to be changed'"}})],1)],1)],1)],1),_c('v-divider'),_c('h3',{staticStyle:{"margin-top":"15px"}},[_vm._v("Records ("+_vm._s(_vm.dateFunctions.formatDate(_vm.current_date))+")")]),_c('v-data-table',{attrs:{"headers":[
      {
        text: 'Task No.',
        value: 'task_no',
        width: '10%',
      },
      {
        text: 'Description',
        value: 'description',
        width: '75%',
      },
      {
        text: 'Duration',
        value: 'duration',
        width: '15%',
      } ],"items":_vm.tab_booked_today,"loading":_vm.l_booked_today,"sort-by":"duration","multi-sort":true,"sort-desc":true,"group-by":"project_id","no-data-text":"There are no records for today."},on:{"click:row":_vm.triggerSelectBookedTodayRecord},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
    var item = ref.item;
return [(item.marked_need_to_change === 'true')?_c('i',{staticClass:"fad fa-info-circle",staticStyle:{"color":"orange"},attrs:{"title":"Need to be changed"}}):_vm._e(),(item.marked_further_action === 'true')?_c('i',{staticClass:"fad fa-info-circle",staticStyle:{"color":"red"},attrs:{"title":"Further action"}}):_vm._e(),_c('span',[_vm._v(_vm._s(item.description))])]}},{key:"item.duration",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.duration)+" h ")]}},{key:"group.header",fn:function(ref){
    var items = ref.items;
return [_c('td',{attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.project_mapping[items[0].project_id])+" ")]),_c('td',{attrs:{"colspan":"2"}},[_vm._v(_vm._s(_vm.getTotalDuration(items))+" h")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }